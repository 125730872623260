import {pathList} from "../routes/path";
import twitterImg from '../assets/images/symbols/twitter.svg'
import instaImg from '../assets/images/symbols/insta.svg'
// import threadsImg from '../assets/images/symbols/threads.svg'
import discordImg from '../assets/images/symbols/discord.svg'
import socialMediaImg from '../assets/images/symbols/reddit.svg'
import youtubeImg from '../assets/images/symbols/youtube.svg'
import tiktokImg from '../assets/images/symbols/tiktok.svg'
import telegramImg from '../assets/images/symbols/telegram.svg'
import emailImg from '../assets/images/symbols/email.svg'
import explore from '../assets/images/symbols/explore.svg'
import create from '../assets/images/symbols/create.svg'
import stats from '../assets/images/symbols/stats2.svg'

export const HEADER_NAV = [
    {title: 'Drops', link: pathList.launchpad.path, image: explore},
    {title: 'Explore', link: pathList.explore.path, image: explore},
    {title: 'Stats', link: pathList.stats.path, image: stats},
    {title: 'Create', link: pathList.create.path, image: create},
]

export const HEADER_NAV_WHITELIST = [
    {title: 'Drops', link: pathList.launchpad.path, image: explore},
    {title: 'Explore', link: pathList.explore.path, image: explore},
    {title: 'Stats', link: pathList.stats.path, image: stats},
    {title: 'Create', link: pathList.create.path, image: create},
]

export const FOOTER_NAV = [
    {title: 'Explore', link: pathList.explore.path, image: explore},
    {title: 'Stats', link: pathList.stats.path, image: stats},
    {title: 'Create', link: pathList.create.path, image: create},
    {title: 'Guides', link: pathList.mainPage.path, image: create},
]

export const SOCIALS_DATA = [
    {link: 'https://twitter.com/PawzaarOfficial', image: twitterImg},
    {link: 'https://www.instagram.com/pawzoneofficial/', image: instaImg},
    // {link: 'https://www.threads.net/@pawzoneofficial', image: threadsImg},
    {link: 'https://discord.gg/pawzone', image: discordImg},
    {link: 'https://t.me/PawzoneCommunity', image: telegramImg},
    {link: 'https://youtube.com/@PawZoneOfficial', image: youtubeImg},
    {link: 'https://www.tiktok.com/@pawzoneofficial', image: tiktokImg},
    {link: 'https://www.reddit.com/r/pawzoneshib/', image: socialMediaImg},
    {link: 'mailto:support@pawzone.io', image: emailImg},
]

export const MARKETPLACE_NAV = [
    {title: 'Marketplace', link: ''},
    {title: 'Art', link: ''},
    {title: 'Community', link: ''},
    {title: 'Gaming', link: ''},
    {title: 'Memberships', link: ''},
    {title: 'Music', link: ''},
    {title: 'Photography', link: ''},
]
