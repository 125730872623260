export const SIDEBAR_GUIDES = [
    {id: 1, text: 'Getting Started Guide'},
    {id: 2, text: 'Buying, Selling, Offers, and Transferring NFTs'},
    {id: 3, text: '$SHIB Burns, Platform Fees, and Creator Fees'},
    {id: 4, text: 'Creating Collections and NFTs'},
    {id: 5, text: 'Discovering NFT Collections'},
    {id: 6, text: 'How to Get Your Collection Verified'},
    {id: 7, text: 'Marketplace Analytics and Insights'},
    {id: 8, text: 'Favoriting collections and NFTs'},
    {id: 9, text: 'Pawzaar Launchpad, Intake Form, and Marketing'},
    {id: 10, text: 'Shibarium NFT Generator Services (Coming Soon)'},
    {id: 11, text: 'Pawzaar NFT Bridge (Coming Soon)'},
    {id: 12, text: 'Shibarium Minter (Coming Soon)'},
]









