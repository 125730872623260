import React, {useRef, useState} from 'react';
import styles from './styles.module.scss'
import cn from "classnames";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import burger from '../../assets/images/symbols/menu.svg'
import useOutsideClick from "../../hooks/dom/useOutsideClick";

type Props = {
    title: string,
    data: any,
    activeId: number,
    setActiveId: any
};

const GuidesSideBar = ({title, data, activeId, setActiveId}: Props) => {
    const [isOpen, setIsOpen] = useState(false)
    const windowDimensions = useWindowDimensions()
    const wrapperRef: any = useRef<HTMLInputElement>(null)
    const contentRef: any = useRef<HTMLInputElement>(null)

    const onToggle = () => {
        if (!isMobile) return
        setIsOpen(!isOpen)
    }

    const onClose = () => setIsOpen(false)

    const onSetActiveId = (id: any) => {
        setActiveId(id)
        onClose()
    }

    const isMobile = windowDimensions.width <= 800

    useOutsideClick(contentRef, onClose, wrapperRef)

    return (
        <div className={styles.guidesSideBar} ref={wrapperRef}>
            <p className={styles.title} onClick={onToggle}>
                {isMobile && <img src={burger} alt={''}/>}
                {title}
            </p>

            {!isMobile && <div className={styles.list}>
                {data?.map((item: any, key: number) =>
                    <p
                        key={key}
                        onClick={() => setActiveId(item?.id)}
                        className={cn(activeId === item?.id && styles.active)}
                    >
                        {item?.text}
                    </p>)
                }
            </div>}

            {isMobile && isOpen && <div className={styles.list} ref={contentRef}>
                {data?.map((item: any, key: number) =>
                    <p
                        key={key}
                        onClick={() => onSetActiveId(item?.id)}
                        className={cn(activeId === item?.id && styles.active)}
                    >
                        {item?.text}
                    </p>)
                }
            </div>}

        </div>
    );
};

export default GuidesSideBar;
