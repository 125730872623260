import React from 'react';
import styles from './styles.module.scss'
import logo from '../../assets/images/logos/Pawzaar Orange Night.svg'
import {FOOTER_NAV, MARKETPLACE_NAV, SOCIALS_DATA} from "../../constants/header";
import cn from "classnames";
import {useNavigate} from "react-router-dom";
import useWindowDimensions from "../../hooks/dom/useWidowDimensions";
import {TABLET} from "../../constants";
import {pathList} from "../../routes/path";
import useHandleAuth from "../../hooks/auth/useHandleAuth";
import {useSelector} from "react-redux";
import SignUpModal from "../Modals/SignUpModal/SignUpModal";
import useHandleModal from "../../hooks/dom/useHandleModal";

const Footer = () => {
    const customer = useSelector((state: any) => state.customer.data)

    const navigate = useNavigate()
    const handleAuth = useHandleAuth()
    const windowDimensions = useWindowDimensions()
    const handleSignUpModal = useHandleModal()
    const isTablet = windowDimensions?.width <= TABLET

    const onClickNavLink = (link: string) => {
        if (!customer?._id && link === pathList.create.path)
            return handleAuth.connectWallet(handleSignUpModal.open, () => navigate(link))

        navigate(link)
    }

    return (
        <div className={styles.footer}>
            <div className={styles.topBlock}>
                <div className={cn(styles.content, styles.topBlock_content)}>
                    <div className={styles.subscribe_block}>
                        <p className={styles.stay}>Keep yourself updated</p>
                        <p className={styles.stay_text}>Subscribe to our mailing list and be the first to receive
                            updates on future releases, NFT drops, the latest news, and more.</p>
                        <div className={styles.subscribe}>
                            <input placeholder={'Your email'} className={styles.subscribe_input}/>
                            <div className={styles.subscribe_button}>Subscribe</div>
                        </div>
                    </div>

                    <div>
                        <p className={styles.join}>Join the community</p>
                        <div className={styles.socials}>
                            {SOCIALS_DATA?.map((item, key) => <a
                                key={key}
                                href={item?.link}
                                target="_blank"
                                rel="noreferrer noopener"
                                className={styles.social_item}
                            >
                                <img src={item?.image} alt={''}/>
                            </a>)}
                        </div>
                    </div>

                </div>
            </div>

            <div className={styles.middleBlock}>
                <div className={cn(styles.content, styles.middleBlock_content)}>

                    <div className={styles.nav_block}>
                        <div>
                            <img src={logo} alt={''} className={styles.logo}
                                 onClick={() => onClickNavLink(pathList.mainPage.path)}/>
                            <p className={styles.logo_text}>Unleash the power of Shiba Inu in the world’s first digital
                                NFT marketplace on the Shibarium network. Buy. Sell. Create. Burn.</p>
                        </div>

                        {!isTablet && <div className={styles.nav}>
                            {FOOTER_NAV?.map((item, key) => <a href={'#guides'} className={styles.nav_item} key={key}>
                                <p onClick={() => onClickNavLink(item?.link)}>
                                    {item?.title}
                                </p>
                            </a>)}
                        </div>}
                    </div>


                    <div className={styles.marketplace_nav_wrapper}>
                        {isTablet && <div className={styles.nav}>
                            {FOOTER_NAV?.map((item, key) => <p key={key} onClick={() => onClickNavLink(item?.link)}>
                                {item?.title}
                            </p>)}
                        </div>}


                        <div className={styles.marketplace_nav}>
                            {MARKETPLACE_NAV?.map((item, key) => <p key={key}
                                                                    onClick={() => onClickNavLink(item?.link)}>
                                {item?.title}
                            </p>)}
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.bottomBlock}>
                    <p>© 2022-2023 PawZone Enterprises</p>
                    <div className={styles.privPolicy}>
                        <p onClick={() => onClickNavLink(pathList.privacyPolicy.path)}>Privacy Policy</p>
                        <p onClick={() => onClickNavLink(pathList.terms.path)}>Terms of Service</p>
                    </div>
                </div>
            </div>

            {handleSignUpModal.isActive && <SignUpModal
                isOpen={handleSignUpModal.isActive}
                onClose={handleSignUpModal.close}
            />}


        </div>
    );
};

export default Footer;
