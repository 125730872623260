import React, {useState} from 'react';
import MainLayout from "../../layouts/MainLayout/MainLayout";
import styles from "./styles.module.scss";
import GuidesSideBar from "../../components/GuidesSideBar/GuidesSideBar";
import {SIDEBAR_GUIDES} from "../../constants/guides";
import img1 from '../../assets/images/guides/img.png'
import img2 from '../../assets/images/guides/img_1.png'

const GuidesPage = () => {
    const [activeId, setActiveId] = useState(1)

    return (
        <MainLayout isFullWidth>
            <div className={styles.privacyPolicyPage}>
                <GuidesSideBar
                    activeId={activeId}
                    setActiveId={setActiveId}
                    title={'Table of Contents'}
                    data={SIDEBAR_GUIDES}
                />

                <div className={styles.wrapper}>


                    {activeId === 1 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 1: Getting Started Guide</p>
                        <p className={styles.subtitle}>Set Up Your Wallet</p>
                        <p className={styles.text}><span>Step 1</span> Install MetaMask</p>
                        <div className={styles.img}>
                            <img style={{maxWidth: '500px'}} src={img1} alt={''} width={500}/>
                        </div>
                        <div className={styles.link}>
                            <span>Link:</span>
                            <a href={'https://metamask.io/download/'} target="_blank" rel="noopener noreferrer">
                                https://metamask.io/download/
                            </a>
                        </div>
                        <p className={styles.text}><span>Step 2</span> Create and store your wallet seed phrase in a
                            safe location</p>
                        <p className={styles.text}>Do not share this phrase with anyone. We recommend writing your seed
                            phrase on paper and storing it in 3 safe locations. [Avoid saving on the computer]</p>
                        <p className={styles.text}><span>Step 3</span> Connect MetaMask to the Shibarium network</p>
                        <div className={styles.img}>
                            <img style={{maxWidth: '700px'}} src={img2} alt={''} width={700}/>
                        </div>
                        <div className={styles.link}>
                            <span>Link:</span>
                            <a href={'https://chain.shibrpc.com/'} target="_blank" rel="noopener noreferrer">
                                https://chain.shibrpc.com/
                            </a>
                        </div>
                        <p className={styles.text}>Your wallet should now be connected to the Shibarium blockchain</p>

                        <p className={styles.subtitle}>Creating an Account</p>
                        <div className={styles.link}>
                            <span>X Video:</span>
                            <a href={'https://x.com/PawzaarOfficial/status/1704939907087773746?s=20'} target="_blank"
                               rel="noopener noreferrer">
                                https://x.com/PawzaarOfficial/status/1704939907087773746?s=20
                            </a>
                        </div>

                        <p className={styles.text}><span>Step 1</span> Go to your profile page</p>
                        <p className={styles.text}><span>Step 2</span> Click “Edit Profile”</p>
                        <p className={styles.text}><span>Step 3</span> Type in your username and Twitter</p>


                        <p className={styles.subtitle}>Fund Your Wallet</p>

                        <p className={styles.text}>Purchase tokens on ShibaSwap</p>
                        <p className={styles.text}>Bridge tokens onto the Shibarium network. Here is a great video
                            created by CRYPTOANGEL on how to do this: <a
                                href={'https://www.youtube.com/watch?v=hFKYWEpjPDQ'} target="_blank"
                                rel="noopener noreferrer">https://www.youtube.com/watch?v=hFKYWEpjPDQ</a></p>
                        <p className={styles.text}>Once you have completed all these steps, you are ready to get
                            active!</p>
                    </div>}

                    {activeId === 2 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 2: Buying, Selling, and Transferring NFTs</p>
                        <div className={styles.link}>
                            <span>X Video:</span>
                            <a href={'https://x.com/PawzaarOfficial/status/1708195498451165366?s=20'} target="_blank"
                               rel="noopener noreferrer">
                                https://x.com/PawzaarOfficial/status/1708195498451165366?s=20
                            </a>
                        </div>
                        <p className={styles.subtitle}>Buying</p>
                        <p className={styles.text}>In order to buy an NFT, you must first make sure you have enough
                            funds to make the payment the seller is requesting.</p>
                        <p className={styles.text}>You can buy an NFT that is listed for sale on the Store Page, NFT
                            page, or someone else’s profile page.</p>
                        <p className={styles.subtitle}>Selling</p>
                        <p className={styles.text}>In order to sell an NFT all you would need to do is list it. You can
                            list the item through
                            the Store Page, Profile Page, or NFT Page</p>
                        <p className={styles.subtitle}>Transferring</p>
                        <p className={styles.text}>In order to transfer an NFT from one wallet to another, copy the
                            address of the wallet you would like to send your NFT to and paste it onto the text box.
                            Then click “Transfer”</p>
                    </div>}

                    {activeId === 3 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 3: $SHIB Burns, Platform Fees, and Creator Fees</p>
                        <p className={styles.subtitle}>$SHIB Burns</p>
                        <p className={styles.text}>a. Occurs immediately after every trade</p>
                        <p className={styles.text}>b. Seller is free to set how much of their sale will be sent to the
                            $SHIB burn address</p>

                        <p className={styles.text_bold}>There are 3 ways in which Pawzaar results in burns:</p>
                        <ol type={'1'} className={styles.list}>
                            <li>Every transaction on the Shibarium network burns $SHIB <p>○ The Shibarium network
                                contains a feature where anytime a transaction is made,
                                a portion of the fees are sent to the SHIB burn address</p></li>
                            <li>A portion of every sale on Pawzaar is sent to the $SHIB burn address <p>○ 0.5% of the
                                platform fee for every sale is automatically sent to the SHIB burn
                                address via smart contract</p></li>
                            <li>The seller has the option to set a percentage of their sale that will automatically be
                                sent to the $SHIB burn address <p>○ Whenever you list an NFT for sale, you have the
                                    option
                                    to place a percentage of your sale that you would like to be sent to the SHIB burn
                                    address immediately after purchase</p></li>
                        </ol>

                        <p className={styles.subtitle}>Platform Fees</p>
                        <p className={styles.text}>- Platform fees for sales are set at 1.8%. 0.5% is sent to the $SHIB
                            burn address whereas the
                            remaining 1.3% is for maintenance, employees, and future funding.</p>
                        <p className={styles.subtitle}>Creator Fees</p>
                        <p className={styles.text}>- Creators are able to set royalties to their collections up to 10%.
                            Creator fees ensure that
                            they receive a share of the profits when their NFTs are resold in the secondary market.</p>
                    </div>}

                    {activeId === 4 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 4: Creating Collections and NFT</p>
                        <p className={styles.text}>Upcoming YouTube Video</p>
                    </div>}

                    {activeId === 5 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 5: Discovering NFT Collections (Coming Soon)</p>
                        <p className={styles.text}>Upcoming YouTube Video</p>
                    </div>}

                    {activeId === 6 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 6: How to Get Your Collection Verified</p>
                        <p className={styles.text}>Reach a total trading volume of $5k worth of SHIB and then contact
                            <a href={'mailto:verify@pawzaar.io'}> verify@pawzaar.io</a>. Provide a 2-3 sentence overview
                            of your collection, a link to the
                            collection page and a link to the smart contract if it was created outside of the platform.
                            We will get back to you within the next 48 hours.</p>
                    </div>}

                    {activeId === 7 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 7: Marketplace Analytics and Insights (Coming Soon)</p>
                    </div>}

                    {activeId === 8 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 8: Favoriting collections and NFTs (Coming Soon)</p>
                    </div>}

                    {activeId === 9 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 9: Pawzaar Launchpad, Intake Form, and Marketing</p>
                        <p className={styles.subtitle}>Pawzaar Launchpad</p>
                        <p className={styles.text}>Our launchpad allows members to mint their collections directly on
                            Pawzaar. This gives
                            collections much more exposure and opportunities to mint out.</p>
                        <p className={styles.text}>All you have to do is hand us the collection and we will take it from
                            there. We can make it
                            worek if you have added whitelists, airdrops, or delayed reveals. Creators will be able to
                            set their creator fee and have the option to set an amount of the initial mint that will be
                            sent to
                            the SHIB burn address.
                        </p>
                        <p className={styles.text}>No more need to build your own entire minting website. Leave it to
                            us. In return, 5% of the
                            mint sale is given to Pawzaar for providing these services.
                        </p>
                        <p className={styles.text}>If you wish to use our launchpad services be sure to fill out the
                            intake form</p>

                        <p className={styles.subtitle}>Intake Form</p>
                        <p className={styles.text}>FIll out our intake <a target="_blank"
                                                                          rel="noopener noreferrer"
                                                                          href={'https://zi343o6nbnt.typeform.com/to/ba7cjnLO?typeform-source=linktr.ee'}>form</a> if
                            you are interested in having your NFT on our launchpad, want us
                            to promote your collection, do collaborations together, or all of the above.</p>

                        <p className={styles.subtitle}>Promotions and Marketing</p>
                        <p className={styles.text}>If you wish to have your collection promoted on Pawzaar and even be
                            spotlighted on the main page.</p>
                        <p className={styles.text}>We will help market your project and gain the traction you need for
                            success. If interested, fill out the intake form or contact us at <a
                                href={'mailto:marketing@pawzaar.io'}> marketing@pawzaar.io</a></p>
                        <p className={styles.text}>If you are project that would like to partner up with Pawzaar</p>
                    </div>}

                    {activeId === 10 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 10: Shibarium NFT Generator Services (Coming Soon)</p>
                    </div>}

                    {activeId === 11 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 11: Pawzaar NFT Bridge (Coming Soon)</p>
                    </div>}

                    {activeId === 12 && <div className={styles.block}>
                        <p className={styles.title}>GUIDE 12: Shibarium Minter (Coming Soon)</p>
                    </div>}

                </div>
            </div>
        </MainLayout>
    );
};

export default GuidesPage;
